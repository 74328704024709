<template>
  <div class="home">
    <!-- ?container -->
    <div class=" h-100 px-5">
      <div class="
          h-100
          d-flex
          flex-wrap
          align-items-center
          justify-content-center
          blockMenu
        ">
        <a-button block class="h-auto shadow py-5 mb-3 mb-lg-0 mx-4" :class="{ 'd-none': !env?.bigData }"
          @click="goto('bigData')">
          <span class="d-flex align-items-center justify-content-center mb-3 mt-3">
            <IconSVG name="bigData" />
            <span class="ms-2 fw-bold f-roboto text-start">DATA<br />COLLECTION</span>
          </span>
          <span class="text-center h6 fs-5 fw-light">ระบบรวบรวมและจัดการ<br />ฐานข้อมูลกีฬา</span>
        </a-button>
        <a-button block class="h-auto shadow py-5 mb-3 mb-lg-0 mx-4" :class="{ 'd-none': !env?.academy }"
          @click="goto('academy')">
          <span class="d-flex align-items-center justify-content-center mb-3 mt-3">
            <IconSVG name="academy" />
            <span class="ms-2 fw-bold f-roboto">ACADEMY</span>
          </span>
          <span class="text-center h6 fs-5 fw-light">ระบบขอใบอนุญาต<br />ประกอบสถานฝึกสอนกีฬา</span>
        </a-button>
        <a-button block class="h-auto shadohttps://twitter.com/ewaraha/status/1701111960367198303w py-5 mb-3 mb-lg-0 mx-4"
          :class="{ 'd-none': !env?.eLearning }" @click="goto('eLearning')">
          <span class="d-flex align-items-center justify-content-center mb-3 mt-3">
            <IconSVG name="eLearning" />
            <span class="ms-2 fw-bold f-roboto">E-LEARNING</span>
          </span>
          <span class="text-center h6 fs-5 fw-light">ระบบคลังความรู้<br />ดิจิทัล</span>
        </a-button>
        <a-button block class="h-auto shadow py-5 mb-3 mb-lg-0 mx-4"
          :class="{ 'd-none': !env?.report || env?.report === null }" @click="goto('report')">
          <span class="d-flex align-items-center justify-content-center mb-3 mt-3">
            <IconSVG name="report" />
            <span class="ms-2 fw-bold f-roboto">KPI</span>
          </span>
          <span class="text-center h6 fs-5 fw-light">ระบบจัดเก็บฐานข้อมูล
            <br />สถิติ ประมวลผล</span>
        </a-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, watch, computed, toRefs } from "vue";
import { useRequest } from "vue-request";
import IconSVG from "@/components/IconSVG.vue";
import { useAuth } from "@/_modules/auth";
import { IAccount } from "@/_utils/types";
import { appService } from "@/_services";
import { Roles } from '@/_utils/types';

interface IENV {
  bigData: boolean;
  academy: boolean;
  eLearning: boolean;
  report: boolean;
}

export default defineComponent({
  name: "Home",
  components: {
    IconSVG,
  },
  setup() {
    // const { VUE_APP_FEATURE_BIGDATA, VUE_APP_FEATURE_ELEARNING, VUE_APP_FEATURE_ACADEMY } = process.env
    const { token, account, featureTokens } = useAuth();
    const { data: result, loading } = useRequest(appService.getFeatures, {
      ready: computed(() => !!token.value),
    });
    const roles = reactive<Roles>({
      bigData: null,
      academy: null,
      eLearning: null,
      report: null
    })
    const env = reactive<IENV>({
      bigData: false,
      academy: false,
      eLearning: false,
      report: false
    });
    // watch([loading], () => {
    //   if (!loading.value && result.value) {

    //     const { data } = result.value;


    //     env.bigData = ["true", true].includes(
    //       data?.feature.FEATURE_BIGDATA ?? false
    //     );
    //     env.academy = ["true", true].includes(
    //       data?.feature.FEATURE_ACADEMY ?? false
    //     );
    //     env.eLearning = ["true", true].includes(
    //       data?.feature.FEATURE_ELEARNING ?? false
    //     );
    //     env.report = ["true", true].includes(
    //       data?.feature.FEATURE_KPI ?? false
    //     );
    //   }
    // });

    watch([loading,account], () => {
      if (!loading.value && account.value) {
        // const { data } = result.value;
        const currentAccount = account.value ?? null
        let { bigData, academy, eLearning ,report} = toRefs(roles)
        console.log('roles: ' ,currentAccount,roles)
        bigData.value = currentAccount?.account_roles.find(ele => ele.role_featured === 'BIGDATA')?.roles_permission ?? null
        academy.value = currentAccount?.account_roles.find(ele => ele.role_featured === 'ACADEMY')?.roles_permission ?? null
      eLearning.value = currentAccount?.account_roles.find(ele => ele.role_featured === 'ELEARNING')?.roles_permission ?? null
      report.value = currentAccount?.account_roles.find(ele => ele.role_featured ==='KPI')?.roles_permission ?? null
        env.bigData =!!bigData.value?.permissions
        env.academy =!!academy.value?.permissions
        env.eLearning =!!eLearning.value?.permissions
        env.report =!!report.value?.permissions

        // env.bigData = VUE_APP_FEATURE_BIGDATA === 'true' && !!bigData.value?.permissions
        // let { bigData, academy, eLearning } = toRefs(roles)
        // env.bigData = ["true", true].includes(
        //   data?.feature.FEATURE_BIGDATA ?? false
        // );
        // env.academy = ["true", true].includes(
        //   data?.feature.FEATURE_ACADEMY ?? false
        // );
        // env.eLearning = ["true", true].includes(
        //   data?.feature.FEATURE_ELEARNING ?? false
        // );
        // env.report = ["true", true].includes(
        //   data?.feature.FEATURE_KPI ?? false
        // );
      }
    });

    /* watchEffect(() => {
      const currentAccount = account.value ?? null
      let { bigData, academy, eLearning } = toRefs(roles)
      bigData.value = currentAccount?.account_roles.find(ele => ele.role_featured === 'BIGDATA')?.roles_permission ?? null
      academy.value = currentAccount?.account_roles.find(ele => ele.role_featured === 'ACADEMY')?.roles_permission ?? null
      eLearning.value = currentAccount?.account_roles.find(ele => ele.role_featured === 'ELEARNING')?.roles_permission ?? null

      env.bigData = VUE_APP_FEATURE_BIGDATA === 'true' && !!bigData.value?.permissions
      env.academy = VUE_APP_FEATURE_ACADEMY === 'true' && !!academy.value?.permissions
      env.eLearning = VUE_APP_FEATURE_ELEARNING === 'true' && !!eLearning.value?.permissions
    }) */
    const goto = (type: string) => {
      const { account_roles } = account.value as IAccount;


      switch (type) {
        case "bigData": {
          const { roles_permission } =
            account_roles?.find((ele) => ele.role_featured === "BIGDATA") ?? {};
          const { permissions } = roles_permission ?? {};
          const { permission_code } = permissions ?? {};
          const url = permission_code?.toLowerCase().includes("member")
            ? `${process.env.VUE_APP_EXTERNAL_BIGDATA}`
            : `${process.env.VUE_APP_EXTERNAL_BIGDATA_ADMIN}`;
          const featureToken = featureTokens.value?.bigData;
          // window.open('https://sportdataportal.devoncloud.cc/', '_blank')
          window.open(`${url}/sso?token=${featureToken}`, "_blank");
          break;
        }
        case "academy": {
          const { roles_permission } =
            account_roles?.find((ele) => ele.role_featured === "ACADEMY") ?? {};
          const { permissions } = roles_permission ?? {};
          const { permission_code } = permissions ?? {};

          const url = permission_code?.toLowerCase().includes("member")
            ? `${process.env.VUE_APP_EXTERNAM_ACADEMY}`
            : `${process.env.VUE_APP_EXTERNAM_ACADEMY_ADMIN}`;
          const featureToken = featureTokens.value?.academy;
          // window.open('https://sportdataportal.devoncloud.cc/', '_blank')
          window.open(`${url}/sso?token=${featureToken}`, "_blank");
          break;
        }
        case "eLearning": {
          const { roles_permission } =
            account_roles?.find((ele) => ele.role_featured === "ELEARNING") ?? {};
          const { permissions } = roles_permission ?? {};
          const { permission_code } = permissions ?? {};
          const url = permission_code?.toLowerCase().includes("member")
            ? `${process.env.VUE_APP_EXTERNAM_ELEARNING}`
            : `${process.env.VUE_APP_EXTERNAM_ELEARNING_ADMIN}`;
          const featureToken = featureTokens.value?.eLearning;
          // window.open('https://sportdataportal.devoncloud.cc/', '_blank')
          window.open(`${url}/sso?token=${featureToken}`, "_blank");
          break;
        }
        case "report": {
          const { roles_permission } =
            account_roles?.find((ele) => ele.role_featured === "KPI") ?? {};
          const { permissions } = roles_permission ?? {};
          const { permission_code } = permissions ?? {};
          // const url = permission_code?.toLowerCase().includes("member")
          //   ? `${process.env.VUE_APP_EXTERNAM_ELEARNING}`
          //   : `${process.env.VUE_APP_EXTERNAM_ELEARNING_ADMIN}`;
          const url = `${process.env.VUE_APP_EXTERNAL_KPI_REPORT}`
          const featureToken = featureTokens.value?.report;
          // window.open('https://sportdataportal.devoncloud.cc/', '_blank')
          window.open(`${url}/sso?token=${featureToken}`, "_blank");
          break;
        }
        default:
          alert("URL not provide");
          break;
      }
    };
    return {
      env,
      goto,
    };
  },
});
</script>

<style scoped lang="scss">
@import "bootstrap/scss/bootstrap-grid.scss";

.home {
  background-image: url(../assets/images/artboard-ball.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: calc(100vh - 64px); // minus header
  padding-top: 50px;
  padding-bottom: 50px;

  @include media-breakpoint-up(lg) {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .blockMenu {
    padding: 0 48px;
    margin: 0 16px;

    @media (max-width: 600px) {
      padding: 0 8px;
      margin: 0 0px;
    }
  }

  .sat-btn {
    max-width: 300px;
    border-radius: 50px;

    @media (min-width: 1001px) {
      width: 80%;

      :deep(span.anticon) {
        font-size: 4rem;
      }

      span.fw-bold {
        font-size: 2rem;
        line-height: 1;
      }
    }

    @media (min-width: 601px) and (max-width: 1000px) {
      width: 80%;

      :deep(span.anticon) {
        font-size: 3rem;
      }

      span.fw-bold {
        font-size: 1.8rem;
        line-height: 1;
      }
    }

    @media (max-width: 600px) {
      width: 100%;

      :deep(span.anticon) {
        font-size: 2rem;
      }

      span.fw-bold {
        font-size: 1.4rem;
        line-height: 1;
      }
    }
  }

  .sat-btn:enabled:not(:hover) {
    span:first-child {
      color: #383770;
    }
  }
}
</style>