
import { defineComponent, ref, reactive, UnwrapRef } from "vue";
import { ISignInPayload } from "@/_utils/types";
import { authService, accountService } from "@/_services";
import { useRequest } from "vue-request";

type FormState = Pick<ISignInPayload, "account_email">

export default defineComponent({

  setup() {
    const loading = ref<boolean>(false);

    const formRef = ref();
    const formState: UnwrapRef<FormState> = reactive({
      account_email: ""
    });
    const formRules = {
      account_email: [
        {
          required: true,
          message: "Please input your e-mail address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input an e-mail address format",
          trigger: "blur",
        },
      ]
    };

    const {
      run,
      data,
      error,
    } = useRequest(authService.signup, {
      manual: true,
      onError: () => {
        loading.value = false;

      },
      onSuccess: (data:any) => {
        
        console.log('logged in',data)
        // if(data.success){
          alert(data.message)
          loading.value = false
        // }
      },
    });

    const handleRequestFinish = (values:any) => {
      console.log('Request finish', values)
      loading.value = true;

      run({ ...values });

    };

    return {
      formRef,
      formRules,
      formState,
      handleRequestFinish,
      loading
    };
  }
})
